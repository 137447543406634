/***
   "All Can Be a Modal" is a javascript library created by Jasiel Chao --> https://jasielchao.com/.
   -- Don't use it or distribute it without authorization --
***/

document.addEventListener("DOMContentLoaded", () => {
    // Select all the trigger-modal
    let triggerModal_array = document.querySelectorAll('.trigger-modal');

    if(triggerModal_array){
        triggerModal_array.forEach(element => {

            //le asigno un evento a cada enlace
            element.addEventListener('click', function(e) {
                //Prevenimos la accion por defecto de los enlaces
                e.preventDefault();
    
                //Obtenemos la seccion a la que debemos ir con el enlace e.target.attributes.href.value
                let linkTarget = e.target.getAttribute("data-modal");
                    
                /* To prevent a null target */
                if(linkTarget == null){
                    let btnTarget = e.target.parentElement;
                
                    while(linkTarget == null){
                        linkTarget = btnTarget.getAttribute("data-modal");
                        btnTarget = btnTarget.parentElement;
                    }
                }
                
            });
    
        });
    }
   
});

